import "mobx-react-lite/batchingForReactDom";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

import "./TextStyles.css";

import "./chatInput.css";

import "./chatMessage.css";

(async () => {
    // Polyfill "Intl.PluralRules"
    // https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
    if (!Intl.PluralRules) {
        // @ts-expect-error does not provide types
        await import("@formatjs/intl-pluralrules/polyfill-locales");
    }

    // Polyfill "Intl.PluralRules"
    // https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
    if (!Intl.RelativeTimeFormat) {
        // @ts-expect-error does not provide types
        await import("@formatjs/intl-relativetimeformat/polyfill-locales");
    }

    const App = (await import("./App")).default;

    // Add commit hash for deployed versions
    const version = document.createComment(
        `Version ${import.meta.env.VITE_APP_COMMIT_HASH} @ ${import.meta.env.VITE_APP_BUILD_DATE}`,
    );
    document.head.prepend(version);

    ReactDOM.render(<App />, document.getElementById("root"));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();
